import { Tag } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import type { IFormSchema } from 'src/components/Form';
import { MenuOptions, VisibleOptions, OpenPageOptions } from 'src/constants';
import { Icon } from 'src/components/Icons';
import { MenuTypeEnum, VisibleEnum } from 'src/interface';
import styles from './index.module.less';

export const columns: QueryTableColumnProps = [
  {
    title: '菜单名称',
    dataIndex: 'menuName',
    key: 'menuName',
    width: 200,
  },
  {
    title: '菜单路径',
    dataIndex: 'path',
    key: 'path',
    width: 200,
  },
  {
    title: '菜单类型',
    dataIndex: 'menuType',
    key: 'menuType',
    render: (text: number) => {
      return (
        <Tag bordered={false} color="processing">
          {MenuOptions.filter((item) => item.value === text)[0]?.label}
        </Tag>
      );
    },
    width: 150,
  },
  {
    title: '图标',
    dataIndex: 'icon',
    key: 'icon',
    render: (text: string) => {
      return <Icon name={text} size={24} />;
    },
    width: 100,
  },
  {
    title: '排序',
    dataIndex: 'orderNum',
    key: 'orderNum',
    width: 80,
  },
  {
    title: '国际化标识',
    dataIndex: 'locale',
    key: 'locale',
    width: 200,
  },
  {
    title: '权限标识',
    dataIndex: 'permission',
    key: 'permission',
    width: 200,
  },
  {
    title: '菜单状态',
    dataIndex: 'visible',
    key: 'visible',
    width: 100,
    render: (text: string) => {
      return text === VisibleEnum.SHOW ? '展示' : '隐藏';
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  menuName: {
    type: 'string',
    title: '菜单名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单名称',
    },
  },
  path: {
    type: 'string',
    title: '菜单路径',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单路径',
    },
  },
  menuType: {
    type: 'number',
    title: '菜单类型',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择菜单类型',
      options: MenuOptions,
    },
  },
  visible: {
    type: 'number',
    title: '展示状态',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择菜单类型',
      options: VisibleOptions,
    },
  },
};

export const operationSchema: IFormSchema = {
  menuType: {
    type: 'number',
    title: '菜单类型',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择菜单类型',
      options: MenuOptions,
      buttonStyle: 'solid',
      optionType: 'button',
    },
    default: MenuTypeEnum.DIRE,
    'x-validator': [{ required: true, message: '请选择菜单类型' }],
  },
  parentId: {
    type: 'number',
    title: '父级菜单',
    'x-component': 'TreeSelect',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择父级菜单',
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      popupClassName: styles.menu_parent_popup,
    },
    'x-validator': [{ required: true, message: '请选择父级菜单' }],
    'x-reactions': {
      dependencies: ['menuType'],
      fulfill: {
        schema: {
          'x-visible': '{{ $deps[0] !== 0 }}',
        },
      },
    },
  },
  menuName: {
    type: 'string',
    title: '菜单名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单名称',
    },
    'x-validator': [{ required: true, message: '请输入菜单名称' }],
  },
  path: {
    type: 'string',
    title: '菜单路径',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单路径',
    },
    'x-validator': [{ required: true, message: '请输入菜单路径' }],
    'x-reactions': {
      dependencies: ['menuType'],
      fulfill: {
        schema: {
          'x-visible': '{{ $deps[0] !== 2 }}',
        },
      },
    },
  },
  icon: {
    type: 'string',
    title: '菜单图标',
    'x-component': 'InputIcon',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入菜单图标',
    },
    'x-reactions': {
      dependencies: ['menuType'],
      fulfill: {
        schema: {
          'x-visible': '{{ $deps[0] !== 2 }}',
        },
      },
    },
  },
  visible: {
    type: 'string',
    title: '菜单状态',
    'x-component': 'Switch',
    'x-decorator': 'FormItem',
    'x-component-props': {
      checkedChildren: '开启',
      unCheckedChildren: '关闭',
    },
  },
  permission: {
    type: 'string',
    title: '权限标识',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入权限标识',
    },
  },
  locale: {
    type: 'string',
    title: '国际化标识',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入国际化标识',
    },
    'x-reactions': {
      dependencies: ['menuType'],
      fulfill: {
        schema: {
          'x-visible': '{{ $deps[0]!== 2 }}',
        },
      },
    },
  },
  target: {
    type: 'string',
    title: '打开方式',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择打开方式',
      options: OpenPageOptions,
      allowClear: true,
    },
    'x-reactions': {
      dependencies: ['menuType'],
      fulfill: {
        schema: {
          'x-visible': '{{ $deps[0] === 1 }}',
        },
      },
    },
  },
  orderNum: {
    type: 'number',
    title: '排序',
    'x-component': 'NumberPicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入排序',
    },
  },
  remark: {
    type: 'string',
    title: '备注',
    'x-component': 'Input.TextArea',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入备注',
      maxLength: 500,
      showCount: true,
    },
  },
};
